import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "range", "rangeGroup", "label" ]

  connect() {
    let selected = this.rangeTargets.reduce((acc, current) => {
      if (current.checked) {
        acc.push(current)
      }
      return acc
    }, [])

    this.enableRanges(selected)
  }

  toggle(e) {
    let selectedRanges = this.rangeTargets.filter(item => item.checked == true)
    if (selectedRanges.length == 0) {
      this.enableRanges(this.rangeTargets)
    } else {
      this.hadleRangesAvailability(e.target)
    }
  }

  hadleRangesAvailability(toggledItem) {
    let available = []
    let notAvailable = []

    available.push(toggledItem)

    this.rangeGroupTargets.forEach(rangeGroup => {
      let rangeGroupFrom = Number(rangeGroup.getElementsByTagName('span')[0].innerHTML)
      let rangeGroupTo = Number(rangeGroup.getElementsByTagName('span')[1].innerHTML)

      // Skip toggled element
      if (rangeGroup == toggledItem.parentElement) {
        available.push(rangeGroup.querySelectorAll(".rounded")[0])
      } else {
        let avail = this.isRangeAvailable(rangeGroupFrom, rangeGroupTo)
        if (avail) {
          available.push(rangeGroup.querySelectorAll(".rounded")[0])
        } else {
          notAvailable.push(rangeGroup.querySelectorAll(".rounded")[0])
        }
      }
    })

    this.enableRanges(available)
    this.disableRanges(notAvailable)
  }

  isRangeAvailable(from, to) {
    let selectedParents = this.rangeTargets.reduce((acc, current) => {
      if (current.checked) {
        acc.push(current.parentElement)
      }
      return acc
    }, [])

    let found = selectedParents.find(selected => {
      let selectedFrom = Number(selected.getElementsByTagName('span')[0].innerHTML)
      let selectedTo = Number(selected.getElementsByTagName('span')[1].innerHTML)
      
      if (this.rangeOverlappingSelectedInOnePoint(selectedFrom, selectedTo, from, to)) {
        return false
      }

      let result = [
        this.selectedIntervalInsideRange(selectedFrom, selectedTo, from, to),
        this.rangeInsideSelectedInterval(selectedFrom, selectedTo, from, to),
        this.rangeFromInSelectedInterval(selectedFrom, selectedTo, from, to),
        this.rangeToInSelectedInterval(selectedFrom, selectedTo, from, to)
      ]

      return result.includes(true)
    })

    return found == undefined
  }

  enableRanges(ranges) {
    for (let range of ranges) {
      range.disabled = false
      let parent = range.parentElement
      parent.classList.remove('text-gray-500')
      parent.classList.add('text-black')
    }
  }

  disableRanges(ranges) {
    for (let range of ranges) {
      if (!range.checked) {
        range.disabled = true
        let parent = range.parentElement
        parent.classList.remove('text-black')
        parent.classList.add('text-gray-500')
      }
    }
  }

  selectedIntervalInsideRange(selectedFrom, selectedTo, from, to) {
    return (from <= selectedFrom) && (to >= selectedTo)
  }

  rangeInsideSelectedInterval(selectedFrom, selectedTo, from, to) {
    return from >= selectedFrom && to <= selectedTo
  }

  rangeFromInSelectedInterval(selectedFrom, selectedTo, from, to) {
    return from >= selectedFrom && from <= selectedTo
  }

  rangeToInSelectedInterval(selectedFrom, selectedTo, from, to) {
    return to <= selectedTo && to >= selectedFrom
  }

  rangeOverlappingSelectedInOnePoint(selectedFrom, selectedTo, from, to) {
    return to == selectedFrom || from == selectedTo
  }
}
