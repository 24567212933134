import { Controller } from '@hotwired/stimulus';
import { isThisQuarter, isThursday } from 'date-fns';

export default class extends Controller {
  static targets = [ "dataType", "cron", "url", "version","email", "password", "shop", "autoGenerate", "sortimentReportField", "sourceName", "hours", "minutes", "customTime", "ga4AccountId", "ga4PropertyId", "ga4AuthId"]

  connect() {
    this.selectDataType()
  }

  selectDataType() {
    this.selectFreqency()
    switch(this.dataTypeTarget.value) {
      case 'product_feed':
        this.hoursTarget.removeAttribute('disabled')
        this.minutesTarget.removeAttribute('disabled')
        this.showItems([this.urlTarget])

      case 'google_shopping_feed':
        this.hideGa4Properties()
        this.adjustCron('Každou lichou hodinu', '0 1-23/2 * * *')
        this.showItems([this.urlTarget])
        this.hideItems([this.ga4AuthIdTarget, this.versionTarget, this.emailTarget, this.shopTarget, this.autoGenerateTarget, this.passwordTarget, this.sourceNameTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      case 'transaction_feed':
        this.hideGa4Properties()
        this.adjustCron('Každý den ve 02:00', '0 2 * * *')
        this.adjustCron('Každý den ve 05:00', '0 5 * * *')
        this.showItems([this.urlTarget])
        this.hideItems([this.ga4AuthIdTarget, this.versionTarget, this.passwordTarget, this.emailTarget, this.shopTarget, this.autoGenerateTarget, this.sourceNameTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      case 'sortiment_report':
        this.hideGa4Properties()
        this.adjustCron('Každý den ve 02:00', '0 2 * * *')
        this.showItems([this.versionTarget,this.emailTarget, this.shopTarget, this.passwordTarget, this.autoGenerateTarget, , this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        this.hideItems([this.ga4AuthIdTarget, this.urlTarget, this.sourceNameTarget])
        break;

      case 'heureka_feed':
        this.hideGa4Properties()
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.showItems([this.versionTarget])
        this.hideItems([this.ga4AuthIdTarget, this.urlTarget, this.sourceNameTarget, this.emailTarget, this.passwordTarget, this.shopTarget, this.autoGenerateTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      case 'price_check':
        this.hideGa4Properties()
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.showItems([this.urlTarget, this.sourceNameTarget])
        this.hideItems([this.ga4AuthIdTarget, this.versionTarget, this.emailTarget, this.shopTarget, this.passwordTarget, this.autoGenerateTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      case 'ga4':
        this.showGa4Properties()
        this.cronTarget.querySelectorAll('option').forEach(option => { option.remove() })
        this.adjustCron('Každý den v 03:00', '0 3 * * *')
        this.showItems([this.ga4AuthIdTarget, this.cronTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        this.hideItems([this.urlTarget, this.autoGenerateTarget, this.shopTarget, this.passwordTarget, this.emailTarget, this.sortimentReportFieldTarget, this.sourceNameTarget, this.versionTarget])
        break;
      
      case 'shopify_product_feed':
        this.hideGa4Properties()
        this.hideItems([this.ga4AuthIdTarget, this.urlTarget, this.versionTarget, this.emailTarget, this.shopTarget, this.autoGenerateTarget, this.passwordTarget, this.sourceNameTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      case 'shopify_transaction_feed':
        this.hideGa4Properties()
        this.hideItems([this.ga4AuthIdTarget, this.urlTarget, this.versionTarget, this.emailTarget, this.shopTarget, this.autoGenerateTarget, this.passwordTarget, this.sourceNameTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
        break;

      default:
        this.hideGa4Properties()
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.hoursTarget.setAttribute('disabled', true)
        this.minutesTarget.setAttribute('disabled', true)

        const node = document.createElement("option")
        node.text = 'Každý den ve 03:30'
        node.value = '30 3 * * *'
        this.cronTarget.appendChild(node)

        this.hideItems([this.ga4AuthIdTarget, this.versionTarget, this.emailTarget, this.sourceNameTarget, this.shopTarget, this.passwordTarget, this.autoGenerateTarget, this.sortimentReportFieldTarget, this.ga4AccountIdTarget, this.ga4PropertyIdTarget])
    }
  }

  adjustCron(text, cronLine) {
    let node = document.createElement("option")
    node.text = text
    node.value = cronLine
    this.cronTarget.appendChild(node)

    if (this.cronTarget.querySelector(`option`).text == '') {
      this.cronTarget.removeChild(this.cronTarget.querySelector(`option`))
    }

    if (this.dataTypeTarget.value == 'product_feed') {
      this.addOncePerDayOption()
    }
  }

  hideItems(items) {
    items.forEach(target => target.classList.add('hidden'))
  }

  showItems(items) {
    items.forEach(target => target.classList.remove('hidden'))
  }

  hideGa4Properties() {
    document.getElementById('ga4_properties').classList.add('hidden')
  }

  showGa4Properties() {
    document.getElementById('ga4_properties').classList.remove('hidden')
  }

  setCustomCronTime() {
    const opt = document.getElementById('once_per_day')
    opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')
  }

  selectFreqency() {
    if (['product_feed', 'shopify_product_feed'].includes(this.dataTypeTarget.value) && this.cronTarget.options[this.cronTarget.selectedIndex].text == 'Jednou denně') {
      this.customTimeTarget.classList.remove('hidden')
    } else {
      this.customTimeTarget.classList.add('hidden')
    }
  }

  addOncePerDayOption() {
    const opt = document.createElement("option")

    opt.text = 'Jednou denně';
    opt.id = 'once_per_day'
    this.hoursTarget.value = 0
    this.minutesTarget.value = 0
    opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')

    this.cronTarget.appendChild(opt);
  }

  authChange(event) {
    const selectedValue = event.target.value
    const instanceId = this.data.get("instanceIdValue")
    const newUrl = `/admin/instances/${instanceId}/ga4_properties?ga4_auth_record_id=${selectedValue}`
    const turboFrame = document.querySelector('[data-properties="ga4PropertiesFrame"]')

    if (turboFrame) {
      turboFrame.classList.add('hidden')
      turboFrame.src = newUrl
    } else {
      console.error("Turbo Frame with ID 'ga4PropertiesFrame' not found.")
    }
  }
}
