import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["item", "roundPrecision", "delete", "from", "to", "input", "roundValueVisibility", "checkBox", "CustomNumberText", "secondaryInputElement", "secondaryInput", "secondaryInputIcon", "secondaryInputRemoveIcon", "chevron", "parent"]
  static values = { rounding: String, secondaryRounding: String, new: String }

  connect() {
    if (this.newValue) {
      this.secondaryInputTarget.value = ''
    }

    if (this.secondaryInputTarget.value !== '' && this.secondaryInputTarget.value !== 0) {
      this.secondaryInputElementTarget.classList.remove("hidden")
      this.secondaryInputIconTarget.classList.add("hidden")
      this.secondaryInputRemoveIconTarget.classList.remove("hidden")
      this.parentTarget.classList.add("pb-8")
    }
    if (this.roundPrecisionTarget.value !== 'custom') {
      if (this.roundValueVisibilityTarget.value == "false") {

        this.inputTarget.classList.add('hidden')
        this.checkBoxTarget.removeAttribute('checked')
        this.CustomNumberTextTarget.classList.remove('hidden')
      } else {
        this.checkBoxTarget.setAttribute('checked', 'checked')
        this.inputTarget.classList.remove('hidden')
        this.CustomNumberTextTarget.classList.remove('hidden')
      }
    } else {
      this.roundValueVisibilityTarget.value = true
      this.CustomNumberTextTarget.classList.add('hidden')
      this.checkBoxTarget.classList.add('hidden')
    }

    if (this.idValue == 0) {
      this.exampleContainerTarget.classList.add('hidden')
    }

    if (this.checkBoxTarget.checked == false){
      this.secondaryInputIconTarget.classList.add("hidden")
    } 
  }

  remove() {
    this.itemTarget.parentElement.classList.add('hidden')
    this.deleteTarget.value = true
  }

  showEdit(){
    this.itemTarget.classList.toggle("hidden")
    this.chevronTarget.classList.toggle("rotate-90")
  }

  removeSecondValue(){
    this.secondaryInputTarget.value = ''
    this.secondaryInputElementTarget.classList.add("hidden")
    this.secondaryInputIconTarget.classList.remove("hidden")
    this.secondaryInputRemoveIconTarget.classList.add("hidden")
    this.parentTarget.classList.remove("pb-8")
  }

  add() {
    this.secondaryInputElementTarget.classList.remove("hidden")
    this.secondaryInputIconTarget.classList.add("hidden")
    this.secondaryInputRemoveIconTarget.classList.remove("hidden")
    this.parentTarget.classList.add("pb-8")
  }

  checkInterval(e) {
    let from = parseInt(this.fromTarget.value)
    let to = parseInt(this.toTarget.value)
    if (from > to) {
      if (e.target == this.fromTarget) {
        this.toTarget.value = this.fromTarget.value
      } else if (e.target == this.toTarget) {
        this.fromTarget.value = this.toTarget.value
      }
    }
  }

  validateRoundValue() {
    if (this.inputTarget.value === this.secondaryInputTarget.value){
      this.inputTarget.classList.add("error")
      this.secondaryInputTarget.classList.add("error")
    } else {
      this.inputTarget.classList.remove("error")
      this.secondaryInputTarget.classList.remove("error")
    }

    if (this.roundPrecisionTarget.value == 'custom') {
      const CustomPattern = /^\d*((,|\.){1}\d{1}(\d)?)?$/
      let inputValue = this.inputTarget.value
      if (!CustomPattern.test(inputValue)) {
        this.inputTarget.classList.add("error")
      } else {
        this.inputTarget.classList.remove("error")
      }
    }
    else {
      const CustomPattern = /^\d$/
      let inputValue = this.inputTarget.value
      let secondaryInputValue = this.secondaryInputTarget.value
      if (!CustomPattern.test(inputValue)) {
        this.inputTarget.classList.add("error")
      } else {
        this.inputTarget.classList.remove("error")
      }
      if (!CustomPattern.test(secondaryInputValue)){
        this.secondaryInputTarget.classList.add('error')
      } else {
        this.inputTarget.classList.remove("error")
      }
    }
  }

  customRoundValue() {
    if (this.roundPrecisionTarget.value == 'custom') {
      this.checkBoxTarget.checked = true
      this.checkBoxTarget.classList.add('hidden')
      this.secondaryInputIconTarget.classList.remove("hidden")
      this.CustomNumberTextTarget.classList.add('hidden')
      this.roundValueVisibilityTarget.value = true
      this.inputTarget.classList.remove('hidden')
      this.secondaryInputRemoveIconTarget.classList.add("hidden")
      this.parentTarget.classList.remove("pb-8")
      this.secondaryInputTarget.value = ''
      this.inputTarget.value = ''
    } else {
      this.checkBoxTarget.checked = false
      this.checkBoxTarget.classList.remove('hidden')
      this.secondaryInputIconTarget.classList.add("hidden")
      this.inputTarget.classList.add('hidden')
      this.roundValueVisibilityTarget.value = false
      this.CustomNumberTextTarget.classList.remove('hidden')
      this.secondaryInputElementTarget.classList.add("hidden")
      this.secondaryInputRemoveIconTarget.classList.add("hidden")
      this.parentTarget.classList.remove("pb-8")
      this.secondaryInputTarget.value = ''
      this.inputTarget.value = ''
    }

  }

  optionalValue() {
    if (this.checkBoxTarget.checked == true) {
      this.inputTarget.classList.remove('hidden')
      this.secondaryInputIconTarget.classList.remove("hidden")
      this.roundValueVisibilityTarget.value = true
      this.secondaryInputTarget.value = ''
      this.inputTarget.value = 0
    } else if (this.checkBoxTarget.checked == false) {
      this.secondaryInputTarget.value = ''
      this.inputTarget.value = ''
      this.inputTarget.classList.add('hidden')
      this.secondaryInputIconTarget.classList.add("hidden")
      this.secondaryInputRemoveIconTarget.classList.add("hidden")
      this.roundValueVisibilityTarget.value = false
      this.secondaryInputElementTarget.classList.add("hidden")
    }
  }

  validateIntervalValue(e) {
    let inputValue = e.target.value
    if (inputValue.length == 0) {
      e.target.classList.add("error")
    } else {
      e.target.classList.remove("error")
    }
  }
}
