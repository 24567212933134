import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "settings", "dataSources", "analytics", "feedFields", "settingsButton", "dataSourcesButton", "analyticsButton", "feedFieldsButton" ]

  connect() {
    this.selectSettings()
  }

  selectSettings() {
    this.settingsTarget.classList.remove("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.analyticsTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.remove("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
  }

  selectDataSources() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.remove("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.remove("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
  }

  selectAnalytics() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsTarget.classList.remove("hidden")
    this.analyticsButtonTarget.classList.remove("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
  }

  selectFeedFields() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.remove("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.analyticsTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.remove("hidden")
    this.feedFieldsButtonTarget.classList.remove("btn-outline")
  }
}
