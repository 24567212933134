import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["minPurchases", "stepSize", "stepLength", "toggle", "manual", "automatic"]

  connect() {
    this.updateMode()
  }

  updateMode() {
    if (this.isAutomatic()) {
      this.switchAutomatic()
    } else {
      this.switchManual()
    }
  }

  switchAutomatic() {
    this.minPurchasesTarget.value = ''
    this.stepLengthTarget.value = '7' // 7 days
    this.stepSizeTarget.value = ''

    this.automaticTargets.forEach(target => target.classList.remove('hidden'))
    this.manualTargets.forEach(target => target.classList.add('hidden'))
  }

  switchManual() {
    this.automaticTargets.forEach(target => target.classList.add('hidden'))
    this.manualTargets.forEach(target => target.classList.remove('hidden'))
  }

  isAutomatic() {
    return this.toggleTarget.checked
  }
}
